<template>
    <header class="bg-gray-100 border-b border-[#030b12]/10">
        <div class="max-w-[1980px] mx-auto">
            <div class="flex items-center justify-between px-6 py-2 h-14">
                <div class="flex items-center gap-2">
                    <FaIcon :icon="['fas', 'circle-question']" :class="{ 'text-brand-500': isVideoLibraryActive }" />
                    <h1 class="text-surface-800 text-xl font-semibold leading-7">
                        <router-link :to="`/company/${companyId}/help-center`">
                            <span :class="{ 'text-brand-500': isVideoLibraryActive }"> Help center </span>
                        </router-link>
                        {{ isVideoLibraryActive ? '/ Video library' : '' }}
                    </h1>
                </div>
                <div class="flex gap-2">
                    <Button
                        :class="{ '!bg-primary-500/[.12]': showSupportForm }"
                        label="Request support"
                        text
                        size="small"
                        class="font-semibold leading-tight rounded-full text-primary-500"
                        @click="toggleSupportForm"
                    />
                    <Button
                        v-if="isSuperUser"
                        icon="far fa-video-plus"
                        :pt="{ icon: { class: ['!mr-0'] } }"
                        label="Add video"
                        rounded
                        size="small"
                        class="font-semibold leading-tight rounded-full"
                        @click="helpCenterStore.openVideoFormDialog()"
                    />
                </div>
            </div>
        </div>
        <Popover ref="supportPopover" :pt="{ root: { class: ['!max-w-none'] }, arrow: { class: ['hidden'] } }">
            <SupportRequestForm @submit="handleSupportSubmit" @close="closeSupportForm" />
        </Popover>
    </header>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useUserStore } from '@/stores/userStore'
import { useHelpCenterStore } from '@/stores/helpCenter'
import { storeToRefs } from 'pinia'
import SupportRequestForm from './SupportRequestForm.vue'

const helpCenterStore = useHelpCenterStore()
const userStore = useUserStore()
const { isSuperUser } = storeToRefs(userStore)

const showSupportForm = ref(false)
const supportPopover = ref()

watch(() => supportPopover.value?.visible, (visible) => {
    showSupportForm.value = visible || false
})

const toggleSupportForm = () => {
    supportPopover.value?.toggle(event)
}

const closeSupportForm = () => {
    supportPopover.value?.hide()
}

const handleSupportSubmit = (data: { emails: string[], subject: string, description: string }) => {
    console.log('Support request submitted:', data)
    closeSupportForm()
    // TODO: Implement API call to submit support request
}

const route = useRoute()
const isVideoLibraryActive = computed(() => route.path.includes('/video-library'))
const companyId = computed(() => route.params.companyId as string)
</script>

<style scoped></style>
