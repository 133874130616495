<template>
    <HelpCenterHeader />
    <router-view />
    <VideoFormDialog
        v-model:visible="helpCenterStore.state.showVideoFormDialog"
        :is-edit="!!helpCenterStore.state.editVideoData"
        :video-data="helpCenterStore.state.editVideoData"
        @update:visible="handleVisibilityChange"
    />
</template>

<script lang="ts" setup>
import { useHelpCenterStore } from '@/stores/helpCenter'
import HelpCenterHeader from '../components/HelpCenterHeader.vue'
import VideoFormDialog from '../components/VideoFormDialog.vue'

const helpCenterStore = useHelpCenterStore()

const handleVisibilityChange = (visible: boolean) => {
    if (!visible) {
        helpCenterStore.closeVideoFormDialog()
    }
}
</script>
