export type SortOrderNumber = -1 | 1
export type SortOrderString = 'asc' | 'dsc'

export const sortOrderHelpers = {
    // Convert query string to number
    fromQuery: (sortOrder: SortOrderString | undefined): SortOrderNumber => 
        sortOrder === 'asc' ? 1 : -1,
    
    // Convert number to query string
    toQuery: (sortOrder: SortOrderNumber): SortOrderString => 
        sortOrder === 1 ? 'asc' : 'dsc',
    
    // Apply sort order to orderBy for API
    applyToOrderBy: <T extends string>(orderBy: T, sortOrder: SortOrderNumber): T => 
        sortOrder === -1 ? `-${orderBy}` as T : orderBy
}

export interface SortState<T extends string = string> {
    sortOrder?: SortOrderNumber;
    orderBy?: T;
} 