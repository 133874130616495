<template>
    <Toast></Toast>
    <VideoLibraryHeader />
    <div class="max-w-[1980px] mx-auto h-full">
        <DataView
            :value="state.isLoading ? [] : state.helpCenterVideos"
            layout="grid"
            :data-key="'video-library'"
            :pt="{
                root: { class: ['video-library-root'] },
                content: { class: ['h-full'] },
                emptyMessage: { class: ['h-full'] }
            }"
        >
            <template #grid="slotProps">
                <div
                    class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4 p-12"
                >
                    <div v-for="(item, index) of slotProps.items" :key="index" class="flex justify-center">
                        <VideoCard :key="index" :video="item" :can-edit="true" @edit="handleEdit" />
                    </div>
                </div>
            </template>

            <template #empty>
                <div v-if="state.isLoading" class="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4 p-12">
                    <VideoLibrarySkeleton :number-of-cards="9" />
                </div>
                <NoResultsFound v-else />
            </template>
        </DataView>
    </div>
    <!-- Video Details Dialog -->
    <router-view />
</template>
<script setup lang="ts">
import VideoLibraryHeader from '../../components/VideoLibrary/VideoLibraryHeader.vue'
import VideoCard from '../../components/VideoCard.vue'
import { useHelpCenterStore } from '@/stores/helpCenter'
import { storeToRefs } from 'pinia'
import {
    HelpCenterChannel,
    HelpCenterFeature,
    HelpCenterOrderByOptions,
    HelpCenterType,
    type GetHelpCenterUIParams,
    type HelpCenterVideo
} from '@/interfaces/HelpCenter'
import { type SortOrderNumber, type SortOrderString, sortOrderHelpers } from '@/interfaces/Sorting'
import { useToast } from 'primevue/usetoast'

import { isValueInEnum } from '@/utils/helpers'
import { useRouteValidation } from '@/composables/useRouteValidation'
import NoResultsFound from '@/components/dataView/NoResultsFound.vue'
import VideoLibrarySkeleton from '../../components/VideoLibrary/VideoLibrarySkeleton.vue'

const helpCenterStore = useHelpCenterStore()
const { videoFilters, state } = storeToRefs(helpCenterStore)

const handleEdit = (video: HelpCenterVideo) => {
    helpCenterStore.openVideoFormDialog(video)
}

const toast = useToast()

function decodeQueryToState(query: any) {
    const { page, orderBy, sortOrder, search, channel, feature, type } = query

    const filters: Partial<GetHelpCenterUIParams> = {
        page: validatePage(page),
        orderBy: validateOrderBy(orderBy),
        sortOrder: validateSortOrder(sortOrder)
    }

    if (search) {
        filters.search = search
    } else {
        helpCenterStore.removeVideoFilters(['search'])
    }

    if (validateEnum(channel, HelpCenterChannel)) {
        filters.includeChannels = channel.includes(',') ? channel.split(',') : [channel]
    } else {
        helpCenterStore.removeVideoFilters(['includeChannels'])
    }

    if (validateEnum(feature, HelpCenterFeature)) {
        filters.includeFeatures = feature.includes(',') ? feature.split(',') : [feature]
    } else {
        helpCenterStore.removeVideoFilters(['includeFeatures'])
    }

    if (validateEnum(type, HelpCenterType)) {
        filters.includeTypes = type.includes(',') ? type.split(',') : [type]
    } else {
        helpCenterStore.removeVideoFilters(['includeTypes'])
    }

    helpCenterStore.updateVideoFilters(filters)
}

function validatePage(page: any) {
    const pageNumber = parseInt(page)
    return isNaN(pageNumber) || pageNumber < 1 ? 1 : pageNumber
}

function validateOrderBy(orderBy: string | undefined): HelpCenterOrderByOptions {
    if (!orderBy || typeof orderBy !== 'string') {
        return HelpCenterOrderByOptions.PublishTimeAsc
    }
    const validOrderBy = isValueInEnum(orderBy, HelpCenterOrderByOptions)
    return validOrderBy ? (orderBy as HelpCenterOrderByOptions) : HelpCenterOrderByOptions.PublishTimeAsc
}

function validateSortOrder(sortOrder: SortOrderString | undefined): SortOrderNumber {
    return sortOrderHelpers.fromQuery(sortOrder)
}

function validateEnum<T extends Record<string, string>>(input: string | string[], enumType: T): boolean {
    if (!input) {
        return false
    }
    let inputArray = []
    if (Array.isArray(input)) {
        inputArray = [...input]
    } else {
        inputArray = [...input.split(',')]
    }
    const validValues = Object.values(enumType)
    return inputArray.every((value) => validValues.includes(value))
}

function constructExpectedQuery() {
    return constructVideoLibraryQuery()
}

function onValidRoute() {
    loadLazyData()
}

useRouteValidation<GetHelpCenterUIParams>({
    decodeQueryToState,
    constructExpectedQuery,
    onValidRoute,
    filters: videoFilters
})

function constructVideoLibraryQuery() {
    const updatedQueryParams: any = {
        orderBy: videoFilters.value.orderBy,
        sortOrder: sortOrderHelpers.toQuery(videoFilters.value.sortOrder as SortOrderNumber),
        page: videoFilters.value.page?.toString()
    }
    if (videoFilters.value.search) {
        updatedQueryParams.search = videoFilters.value.search
    }
    if (videoFilters.value.includeChannels) {
        updatedQueryParams.channel = videoFilters.value.includeChannels.join(',')
    }
    if (videoFilters.value.includeFeatures) {
        updatedQueryParams.feature = videoFilters.value.includeFeatures.join(',')
    }
    if (videoFilters.value.includeTypes) {
        updatedQueryParams.type = videoFilters.value.includeTypes.join(',')
    }

    return updatedQueryParams
}

async function loadLazyData() {
    state.value.isLoading = true
    helpCenterStore.updateVideoFilters(constructVideoLibraryUIParams())
    try {
        await helpCenterStore.fetchVideos()
        state.value.isLoading = false
    } catch (error) {
        state.value.isLoading = false
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: `Failed to fetch videos.`,
            life: 3000
        })
    }
}

function constructVideoLibraryUIParams(): GetHelpCenterUIParams {
    const { page, size, orderBy, sortOrder } = videoFilters.value

    const params: GetHelpCenterUIParams = {
        page,
        size,
        orderBy,
        sortOrder
    }

    if (videoFilters.value.search) {
        params.search = videoFilters.value.search
    }
    if (videoFilters.value.includeChannels) {
        params.includeChannels = videoFilters.value.includeChannels
    }
    if (videoFilters.value.includeFeatures) {
        params.includeFeatures = videoFilters.value.includeFeatures
    }
    if (videoFilters.value.includeTypes) {
        params.includeTypes = videoFilters.value.includeTypes
    }

    return params
}
</script>

<style lang="scss" scoped>
.video-library-root {
    height: calc(100vh - 104px);
}
</style>
