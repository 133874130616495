<template>
    <TableHeader
        title="Team directory"
        :has-selected-rows="false"
        :has-active-filters="hasActiveFilters"
        full-page
        @clear-all-filters="clearAllFilters"
    >
        <template #filterSection>
            <div class="filters flex gap-2">
                <FilterUserRole
                    :user-roles="role"
                    :users-summary="usersSummary"
                    @get-users-summary-info="onGetUsersSummaryInfo"
                    @update-user-role-filter="onUpdateUserRoleFilter"
                    @remove-user-role-filter="onRemoveUserRoleFilter"
                />
                <FilterUserStatus
                    :active="active"
                    :inactive="inactive"
                    @update-user-active-filter="onUpdateUserStatusFilter"
                    @remove-user-active-filter="onRemoveUserStatusFilter"
                />
                <FilterDeactivatedAt
                    :deactivated-at-before="deactivatedAtBefore"
                    :deactivated-at-after="deactivatedAtAfter"
                    @update-deactivated-at-filter="onUpdateDeactivatedAtFilter"
                    @remove-deactivated-at-filter="onRemoveDeactivatedAtFilter"
                />

                <FilterLastLogin
                    :last-login-before="lastLoginBefore"
                    :last-login-after="lastLoginAfter"
                    @update-last-login-filter="onUpdateLastLoginFilter"
                    @remove-last-login-filter="onRemoveLastLoginFilter"
                />
                <FilterCreatedAt
                    :created-at-before="createdAtBefore"
                    :created-at-after="createdAtAfter"
                    @update-created-at-filter="onUpdateCreatedAtFilter"
                    @remove-created-at-filter="onRemoveCreatedAtFilter"
                />
            </div>
        </template>

        <template #right>
            <div class="flex items-center gap-2 mr-2">
                <IconField class="flex-grow">
                    <InputIcon class="far fa-search" />
                    <InputText
                        v-model="searchQueryInput"
                        size="small"
                        placeholder="Search user"
                        class="pl-10 pr-10 py-2 border rounded-lg w-full"
                    />
                    <!-- Clear 'X' button for search -->
                    <button
                        v-if="searchQueryInput.length > 0"
                        class="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                        @click="clearSearch"
                    >
                        <FaIcon icon="far fa-xmark" />
                    </button>
                </IconField>
            </div>
            <!-- Export button -->
            <Button
                type="button"
                size="small"
                label="Export"
                icon="far fa-file-export"
                :loading="isExportLoading"
                rounded
                :pt="{
                    root: {
                        class: [
                            'bg-primary-200 !text-primary-500 border-none focus:ring-0 hover:!bg-primary-300 focus:!bg-primary-300'
                        ]
                    },
                    icon: { class: ['!mr-0'] }
                }"
                @click="toggle"
            />
            <Popover ref="op" class="bg-gray-100 rounded-2xl shadow border border-surface-950/10 after:content-none">
                <div class="flex flex-col">
                    <div class="text-surface-950 text-sm font-normal leading-tight mb-3">Name Export</div>
                    <InputText id="usersExportName" v-model="usersExportName" class="pl-10 text-sm" />

                    <div class="flex justify-end mt-3">
                        <div class="flex gap-2">
                            <Button type="button" size="small" label="Cancel" rounded text @click="toggle" />
                            <Button type="button" size="small" label="Create" rounded @click="submitExport" />
                        </div>
                    </div>
                </div>
            </Popover>
        </template>
    </TableHeader>
</template>

<script setup lang="ts">
import { watch, computed, onUnmounted, ref } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import FilterCreatedAt from '@/components/dataTable/tableFilter/filters/FilterCreatedAt.vue'
import FilterLastLogin from '@/components/dataTable/tableFilter/filters/FilterLastLogin.vue'
import FilterDeactivatedAt from '@/components/dataTable/tableFilter/filters/FilterDeactivatedAt.vue'
import FilterUserRole from '@/components/dataTable/tableFilter/filters/FilterUserRole.vue'
import FilterUserStatus from '@/components/dataTable/tableFilter/filters/FilterUserStatus.vue'
import { useCompanyUserStore } from '@/stores/companyUsersStore'
import { storeToRefs } from 'pinia'
import TableHeader from '@/components/dataTable/TableHeader.vue'

defineProps<{
    isExportLoading: boolean
}>()

const emit = defineEmits(['get-users-summary-info', 'export-users'])
const op = ref()
const usersExportName = ref<string>('users_export')

const toggle = (event: Event) => {
    op.value.toggle(event)
}

const submitExport = (): void => {
    emit('export-users', usersExportName.value);
    op.value.toggle()
}

const companyUserStore = useCompanyUserStore()
const {
    searchQuery,
    createdAtBefore,
    createdAtAfter,
    lastLoginBefore,
    lastLoginAfter,
    deactivatedAtBefore,
    deactivatedAtAfter,
    role,
    active,
    inactive,
    usersSummary
} = storeToRefs(companyUserStore)

const debouncedUpdateSearchQuery = useDebounceFn(
    (value: string) => {
        companyUserStore.updateSearchQuery(value)
    },
    400,
    { maxWait: 3000 }
)

const searchQueryInput = ref('')

watch(searchQueryInput, (newValue) => {
    debouncedUpdateSearchQuery(newValue)
})

watch(searchQuery, (newValue) => {
    searchQueryInput.value = newValue
})

onUnmounted(() => {
    companyUserStore.removeCreatedAtFilter()
    companyUserStore.removeLastLoginFilter()
    companyUserStore.removeDeactivatedAtFilter()
    companyUserStore.updateSearchQuery('')
})

const clearSearch = () => {
    searchQueryInput.value = ''
}

const onUpdateCreatedAtFilter = (filter: { createdAtBefore: string; createdAtAfter: string }) => {
    companyUserStore.updateCreatedAtFilter(filter)
}

const onRemoveCreatedAtFilter = () => {
    companyUserStore.removeCreatedAtFilter()
}

const onUpdateLastLoginFilter = (filter: { lastLoginBefore: string; lastLoginAfter: string }) => {
    companyUserStore.updateLastLoginFilter(filter)
}

const onRemoveLastLoginFilter = () => {
    companyUserStore.removeLastLoginFilter()
}

const onUpdateDeactivatedAtFilter = (filter: { deactivatedAtBefore: string; deactivatedAtAfter: string }) => {
    companyUserStore.updateDeactivatedAtFilter(filter)
}

const onRemoveDeactivatedAtFilter = () => {
    companyUserStore.removeDeactivatedAtFilter()
}

const onUpdateUserRoleFilter = (filter: { role: string }) => {
    companyUserStore.updateUserRoleFilter(filter)
}

const onRemoveUserRoleFilter = () => {
    companyUserStore.removeUserRoleFilter()
}

const onUpdateUserStatusFilter = (filter: { active: boolean; inactive: boolean }) => {
    companyUserStore.updateUserStatusFilter(filter)
}

const onRemoveUserStatusFilter = () => {
    companyUserStore.removeUserStatusFilter()
}

const onGetUsersSummaryInfo = () => {
    emit('get-users-summary-info')
}

const hasActiveFilters = computed(() => {
    return (
        createdAtBefore.value !== undefined ||
        createdAtAfter.value !== undefined ||
        lastLoginBefore.value !== undefined ||
        lastLoginAfter.value !== undefined ||
        deactivatedAtBefore.value !== undefined ||
        deactivatedAtAfter.value !== undefined ||
        role.value !== undefined ||
        active.value !== undefined ||
        inactive.value !== undefined
    )
})

const clearAllFilters = () => {
    companyUserStore.removeCreatedAtFilter()
    companyUserStore.removeLastLoginFilter()
    companyUserStore.removeDeactivatedAtFilter()
    companyUserStore.removeUserRoleFilter()
    companyUserStore.removeUserStatusFilter()
}
</script>
