<template>
    <TableFilter :name="'sort-data'" :label="'Sort by'" :is-active="isFilterActive" :enable-clear-filter="false">
        <template #icon="{ variant }">
            <FaIcon v-if="sortAsc" icon="far fa-arrow-up-wide-short" :class="variant"></FaIcon>
            <FaIcon v-if="sortDesc" icon="far fa-arrow-down-wide-short" :class="variant"></FaIcon>
        </template>

        <template #value>
            <div
                v-if="isFilterActive"
                class="text-sm m-w-40 text-ellipsis overflow-hidden"
                data-testid="user-role=filter-display"
            >
                <span v-if="sortAsc">
                    <span v-if="orderByTitle"> A <FaIcon icon="far fa-arrow-right"></FaIcon> Z </span>
                    <span v-if="orderByDatePublished">Newest first</span>
                </span>

                <span v-if="sortDesc">
                    <span v-if="orderByTitle"> Z <FaIcon icon="far fa-arrow-right"></FaIcon> A </span>
                    <span v-if="orderByDatePublished">Oldest first</span>
                </span>
            </div>
        </template>

        <template #content>
            <div :key="componentKey" class="w-full">
                <div class="flex justify-center w-full gap-2">
                    <Button
                        data-testid="change-sort-type"
                        text
                        class="border !border-brand-500 !text-brand-500 h-8 w-full"
                        :class="{ 'border-2': sortAsc || sortDesc }"
                        @click="handleChangeSortOrder"
                    >
                        <span v-if="sortAsc">
                            <FaIcon icon="far fa-arrow-up-wide-short" class="text-brand-500 mr-2"></FaIcon>
                            <span v-if="orderByTitle"> A <FaIcon icon="far fa-arrow-right"></FaIcon> Z </span>
                            <span v-if="orderByDatePublished">Newest</span>
                        </span>

                        <span v-if="sortDesc">
                            <FaIcon icon="far fa-arrow-down-wide-short" class="text-brand-500 mr-2"></FaIcon>
                            <span v-if="orderByTitle"> Z <FaIcon icon="far fa-arrow-right"></FaIcon> A </span>
                            <span v-if="orderByDatePublished">Oldest</span>
                        </span>
                    </Button>
                </div>
                <div></div>
            </div>

            <div :key="componentKey" class="grid gap-2 my-4 w-full">
                <div
                    class="flex align-center w-full text-sm py-1 px-4 rounded hover:cursor:pointer"
                    :class="{ 'bg-brand-500/[.12]': orderByTitle }"
                    @click="handleChangeOrderBy('title')"
                >
                    <FaIcon icon="far fa-font-case" class="self-center h-4 w-4"></FaIcon>
                    <span class="flex ml-4 hover:cursor-pointer" :class="{ 'text-brand-700': orderByTitle }"
                        >Title</span
                    >
                </div>
                <div
                    class="flex align-center w-full text-sm py-1 px-4 rounded hover:cursor-pointer"
                    :class="{ 'bg-brand-500/[.12] !text-brand-700': orderByDatePublished }"
                    @click="handleChangeOrderBy('publishTime')"
                >
                    <FaIcon icon="far fa-calendar-day" class="self-center h-4 w-4"></FaIcon>
                    <span class="flex ml-4 hover:cursor-pointer" :class="{ 'text-brand-700': orderByDatePublished }">
                        Date published
                    </span>
                </div>
            </div>
        </template>
    </TableFilter>
</template>

<script lang="ts" setup>
import TableFilter from '@/components/dataTable/tableFilter/TableFilter.vue'
import { HelpCenterOrderByOptions, type DataViewSortState } from '@/interfaces/HelpCenter'
import type { SortOrderNumber } from '@/interfaces/Sorting'
import { computed, ref, watch } from 'vue'

const props = defineProps<{
    orderByProp?: HelpCenterOrderByOptions
    sortOrderProp?: number
}>()

const emit = defineEmits(['on-sort-filter'])

const state = ref<DataViewSortState>({
    orderBy: undefined,
    sortOrder: undefined
})

const componentKey = ref(0)

// Iterate component key on any state change
watch(
    () => state.value,
    () => {
        componentKey.value += 1
    }
)

// Update state on props change
watch(
    () => [props.orderByProp, props.sortOrderProp],
    ([newOrderByProp, newSortOrderProp]) => {
        state.value.orderBy = newOrderByProp ? (newOrderByProp as HelpCenterOrderByOptions) : undefined
        state.value.sortOrder = newSortOrderProp ? (newSortOrderProp as SortOrderNumber) : undefined
    },
    { immediate: true }
)

function handleChangeSortOrder(): void {
    state.value.sortOrder = state.value.sortOrder === -1 ? 1 : -1
    emit('on-sort-filter', { orderBy: state.value.orderBy, sortOrder: state.value.sortOrder })
}

function handleChangeOrderBy(sortBy: 'title' | 'publishTime'): void {
    if (sortBy === 'title') {
        state.value.orderBy = HelpCenterOrderByOptions.TitleAsc
    } else if (sortBy === 'publishTime') {
        state.value.orderBy = HelpCenterOrderByOptions.PublishTimeAsc
    }
    emit('on-sort-filter', { orderBy: state.value.orderBy, sortOrder: state.value.sortOrder })
}

const isFilterActive = computed(() => {
    return state.value.orderBy || state.value.sortOrder ? true : false
})

const sortAsc = computed(() => state.value.sortOrder === 1)
const sortDesc = computed(() => state.value.sortOrder === -1)
const orderByTitle = computed(() => state.value.orderBy === HelpCenterOrderByOptions.TitleAsc)
const orderByDatePublished = computed(() => state.value.orderBy === HelpCenterOrderByOptions.PublishTimeAsc)
</script>
