import { defineStore } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import authApi from '@/api/auth.api'
import type { UserData, UserDataResponse } from '@/api/auth.api'

export const useUserStore = defineStore('user', () => {
    const user = ref<UserData | null>(null)
    const isAuthenticated = ref(false)
    const loading = ref(false)
    const route = useRoute()

    const isSuperUser = computed(() => {
        return user.value?.activeRole === 'superuser'
    })

    onMounted(async () => {
        if (!user.value && route.meta.requiresAuth) {
            await fetchUser()
        }
    })

    const setUser = (userData: UserData | null) => {
        user.value = userData
        isAuthenticated.value = !!userData
    }

    const loginUser = async (username: string, password: string) => {
        loading.value = true
        try {
            const response = await authApi.login(username, password)
            if (response.success) {
                await fetchUser()
            }
            return response
        } catch (error) {
            console.error('Login error:', error)
            throw error
        } finally {
            loading.value = false
        }
    }

    const logoutUser = async () => {
        loading.value = true
        try {
            await authApi.logout()
            setUser(null)
        } catch (error) {
            console.error('Logout error:', error)
            throw error
        } finally {
            loading.value = false
        }
    }

    const forgotPassword = async (email: string) => {
        loading.value = true
        try {
            await authApi.forgotPassword(email)
        } catch (error) {
            console.error('Password reset error:', error)
            throw error
        } finally {
            loading.value = false
        }
    }

    const fetchUser = async () => {
        loading.value = true
        try {
            const userData: UserDataResponse = await authApi.fetchUserData()
            setUser(userData.data)
        } catch (error) {
            console.error('Fetch user error:', error)
            throw error
        } finally {
            loading.value = false
        }
    }

    const resetPassword = async (newPassword: string, token: string) => {
        loading.value = true
        try {
            await authApi.resetPassword(newPassword, token)
        } catch (error) {
            console.error('Password change error:', error)
            throw error
        } finally {
            loading.value = false
        }
    }
    return {
        user,
        isAuthenticated,
        loading,
        loginUser,
        logoutUser,
        forgotPassword,
        fetchUser,
        resetPassword,
        isSuperUser
    }
})
