import { createRouter, createWebHistory, type Router } from 'vue-router'
import TeamDirectoryTable from './components/TeamDirectoryTable.vue'

export const teamDirectoryRoutes = [
    {
        path: '/company/:companyId/team-directory',
        name: 'TeamDirectoryTable',
        component: TeamDirectoryTable,
        meta: { requiresAuth: true }
    }
]

// Conditionally create and export the router only if running standalone
export let router: Router | undefined
if (import.meta.env.VITE_INSTANCE_NAME === 'TeamDirectoryView') {
    router = createRouter({
        history: createWebHistory(),
        routes: teamDirectoryRoutes
    })
}
