export function stringToBool(val: string) {
    return val === 'true' ? true : false
}

/**
 * Validates orderBy value taken from URL query. Ensures it exists within the passed enum
 * @param orderBy extracted from URL, determines how to order a table/data view
 * @param sortOrder extracted from URL, either -1 (dsc), 1 (asc), or undefined (no sort)
 * @param enumType enum to check against, should contain the orderBy option
 * @returns the orderBy option as a key of the passed enum
 */
export function validateAndComputeOrderBy<T extends Record<string, string>>(
    orderBy: string | undefined,
    sortOrder: number | undefined,
    enumType: T
): `${T[keyof T]}` | undefined {
    if (!orderBy) {
        return undefined
    }
    // Extract the values from the Enum type
    const enumValues = Object.values(enumType) as string[]
    // Determine the orderBy option based on the sort order num (-1 is desc, 1 is asc)
    const computedOrderBy = sortOrder === -1 ? `-${orderBy}` : orderBy

    // If the passed enum values don't include the computedOrder by, then it is not a valid value
    if (!enumValues.includes(computedOrderBy)) {
        throw new Error(`Invalid orderBy value: ${computedOrderBy}`)
    }

    // Return the computed orderBy option as a key of the passed enum
    return computedOrderBy as `${T[keyof T]}`
}
/**
 * Determines whether the value passed matches one of the values in the passed enum
 * @param value string value, check if this exists and if it exists within an enum
 * @param enumType enum to check against
 * @returns true if value exists in enum, false if not
 */
export function isValueInEnum<T extends Record<string, string>>(value: string | undefined, enumType: T): boolean {
    if (!value) {
        return false
    }

    const enumValues = Object.values(enumType) as string[]
    return enumValues.includes(value)
}

/**
 * Capitalize first letter of a string
 * @param str string to capitalize first letter of
 */
export function capitalizeFirstLetter(str: string): string {
    return String(str).charAt(0).toUpperCase() + String(str).slice(1)
}

export function camelCaseToTitleCase(str: string): string {
    if (/^[a-z]+$/.test(str)) {
        return str.charAt(0).toUpperCase() + str.slice(1)
    }
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (match) {
        return match.toUpperCase()
    })
}
