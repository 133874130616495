<template>
    <div class="w-[600px] bg-white rounded-lg">
        <div class="flex flex-col gap-6">
            <h2 class="text-xl font-semibold">Submit a support request</h2>

            <div>
                <label class="block text-sm font-medium text-gray-700 mb-1">Contact email</label>
                <div class="relative">
                    <div class="min-h-[42px] p-2 border rounded-md flex flex-wrap gap-2 bg-white">
                        <Chip
                            v-for="(email, index) in emails"
                            :key="index"
                            :label="email"
                            removable
                            class="text-sm"
                            @remove="removeEmail(index)"
                        />
                        <input
                            v-model="newEmail"
                            type="text"
                            placeholder="Add email"
                            class="outline-none flex-1 min-w-[100px]"
                            @keydown.space.prevent="addEmail"
                            @keydown.enter.prevent="addEmail"
                            @keydown.tab="addEmail"
                        />
                    </div>
                </div>
            </div>

            <div>
                <label class="block text-sm font-medium text-gray-700 mb-1">Subject</label>
                <InputText v-model="subject" placeholder="Enter subject" class="w-full text-sm" />
            </div>

            <div>
                <label class="block text-sm font-medium text-gray-700 mb-1">Description</label>
                <Textarea v-model="description" placeholder="Describe your request" class="w-full text-sm" rows="4" />
            </div>

            <div class="flex justify-end gap-3">
                <Button label="Discard" text @click="handleDiscard" />
                <Button label="Submit" :disabled="!isValid" @click="handleSubmit" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useUserStore } from '@/stores/userStore'
import { storeToRefs } from 'pinia'

const emit = defineEmits<{
    (e: 'close'): void
    (e: 'submit', data: { emails: string[]; subject: string; description: string }): void
}>()

const userStore = useUserStore()
const { user } = storeToRefs(userStore)
const emails = ref<string[]>([])
const newEmail = ref('')
const subject = ref('')
const description = ref('')

watch(
    () => user.value?.email,
    (newEmail) => {
        if (newEmail && emails.value.length === 0) {
            emails.value = [newEmail]
        }
    },
    { immediate: true }
)

const isValid = computed(() => {
    return emails.value.length > 0 && subject.value.trim() !== '' && description.value.trim() !== ''
})

const addEmail = () => {
    const email = newEmail.value.trim()
    if (email && isValidEmail(email) && !emails.value.includes(email)) {
        emails.value.push(email)
        newEmail.value = ''
    }
}

const removeEmail = (index: number) => {
    emails.value.splice(index, 1)
}

const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
}

const handleDiscard = () => {
    if (user.value?.email) {
        emails.value = [user.value.email]
    } else {
        emails.value = []
    }
    newEmail.value = ''
    subject.value = ''
    description.value = ''
    emit('close')
}

const handleSubmit = () => {
    if (isValid.value) {
        emit('submit', {
            emails: emails.value,
            subject: subject.value,
            description: description.value
        })
    }
}
</script>

<style scoped>
.p-inputtext {
    @apply text-sm font-normal leading-tight text-surface-700;
}
</style>
