<template>
    <div class="py-14 px-12 max-w-[1980px] mx-auto">
        <div class="flex justify-between items-center mb-4">
            <div>
                <h2 class="text-2xl font-semibold">{{ title }}</h2>
                <p>{{ subtitle }}</p>
            </div>
            <Button label="View all" text class="text-sm font-medium" @click="navigateToLibrary" />
        </div>

        <!-- Loading State -->
        <div v-if="isLoading" class="grid grid-cols-4 gap-4">
            <VideoLibrarySkeleton :number-of-cards="4" />
        </div>

        <!-- Empty State -->
        <div v-else-if="!videos.length" class="text-center py-8">
            <p class="text-lg text-surface-600">No videos available at the moment.</p>
        </div>

        <!-- Carousel Content -->
        <div v-else class="relative">
            <Button
                v-tooltip.right="'Previous'"
                rounded
                size="small"
                class="carousel-nav-button -translate-x-1/2"
                :disabled="atStart"
                @click="scrollPrev"
            >
                <FaIcon icon="fas fa-arrow-left" :class="{ 'text-surface-950': atStart }" />
            </Button>

            <div
                ref="trackRef"
                class="carousel-track"
                @mousedown="startDrag"
                @mousemove="onDrag"
                @mouseup="stopDrag"
                @mouseleave="stopDrag"
                @wheel.prevent="handleWheel"
            >
                <div class="flex gap-4 w-fit my-1">
                    <div v-for="video in sortedVideos" :key="video.id" class="flex-none w-[300px]">
                        <VideoCard :video="video" :can-edit="isSuperUser" @edit="handleEdit" />
                    </div>
                </div>
            </div>

            <Button
                v-tooltip.left="'Next'"
                icon="fas fa-arrow-right"
                rounded
                size="small"
                class="carousel-nav-button translate-x-1/2 right-0"
                :disabled="atEnd"
                @click="scrollNext"
            >
                <FaIcon icon="fas fa-arrow-right" :class="{ 'text-surface-950': atEnd }" />
            </Button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import VideoCard from './VideoCard.vue'
import VideoLibrarySkeleton from './VideoLibrary/VideoLibrarySkeleton.vue'
import type { GetHelpCenterUIParams, HelpCenterVideo } from '@/interfaces/HelpCenter'
import { useUserStore } from '@/stores/userStore'
import { useHelpCenterStore } from '@/stores/helpCenter'
import { storeToRefs } from 'pinia'
import { HelpCenterOrderByOptions } from '@/interfaces/HelpCenter'
const props = defineProps<{
    title: string
    subtitle: string
    filters: Partial<GetHelpCenterUIParams>
}>()

const router = useRouter()
const trackRef = ref<HTMLElement | null>(null)
const atStart = ref(true)
const atEnd = ref(false)

// Drag functionality
const isDragging = ref(false)
const startX = ref(0)
const scrollLeft = ref(0)

const userStore = useUserStore()
const { isSuperUser } = storeToRefs(userStore)

const helpCenterStore = useHelpCenterStore()

const videos = ref<HelpCenterVideo[]>([])
const isLoading = ref(false)

const fetchCarouselVideos = async () => {
    isLoading.value = true
    try {
        const response: HelpCenterVideo[] | undefined = await helpCenterStore.fetchVideos({
            storeResults: false,
            filters: {
                ...props.filters,
                size: 8,
                orderBy: HelpCenterOrderByOptions.PublishTimeAsc,
                sortOrder: -1
            }
        })
        videos.value = response || []
    } catch (error) {
        console.error('Error fetching carousel videos:', error)
    } finally {
        isLoading.value = false
    }
}

// Filter and sort videos
const sortedVideos = computed(() => videos.value)

// Navigation
const checkNavigation = () => {
    if (!trackRef.value) return
    atStart.value = trackRef.value.scrollLeft <= 0
    atEnd.value = trackRef.value.scrollLeft + trackRef.value.clientWidth >= trackRef.value.scrollWidth - 10
}

const scrollNext = () => trackRef.value?.scrollBy({ left: 316, behavior: 'smooth' })
const scrollPrev = () => trackRef.value?.scrollBy({ left: -316, behavior: 'smooth' })

// Drag handlers
const startDrag = (e: MouseEvent) => {
    isDragging.value = true
    startX.value = e.pageX
    scrollLeft.value = trackRef.value?.scrollLeft || 0
}

const onDrag = (e: MouseEvent) => {
    if (!isDragging.value || !trackRef.value) return
    e.preventDefault()
    const walk = startX.value - e.pageX
    trackRef.value.scrollLeft = scrollLeft.value + walk
}

const stopDrag = () => {
    isDragging.value = false
}

// Wheel handler
const handleWheel = (e: WheelEvent) => {
    if (!trackRef.value) return
    const delta = e.shiftKey ? e.deltaY : e.deltaX
    trackRef.value.scrollLeft += delta
}

const navigateToLibrary = () => {
    router.push({
        name: 'VideoLibrary',
        query: { type: props.filters.includeTypes?.[0] }
    })
}

// Lifecycle hooks
onMounted(async () => {
    await fetchCarouselVideos()
    trackRef.value?.addEventListener('scroll', checkNavigation)
    window.addEventListener('resize', checkNavigation)
    checkNavigation()
})

onBeforeUnmount(() => {
    trackRef.value?.removeEventListener('scroll', checkNavigation)
    window.removeEventListener('resize', checkNavigation)
})

const handleEdit = (video: HelpCenterVideo) => {
    helpCenterStore.openVideoFormDialog(video)
}
</script>

<style scoped>
.carousel-nav-button {
    @apply absolute z-10 top-1/2 -translate-y-1/2 !w-10 !h-10 bg-primary-500 opacity-60 hover:opacity-100 disabled:opacity-40 disabled:cursor-not-allowed disabled:bg-[#030b12]/10 border-none;
}

.carousel-track {
    @apply overflow-x-auto relative;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
}

.carousel-track::-webkit-scrollbar {
    display: none;
}
</style>
